<template>
  <div class="ms-container-wrapper">
    <div class="product-slider">
      <VueSlickCarousel
          v-bind="settings"
          class="slider-activation-with-slick mb--0"
          @beforeChange="onProductSlideBeforeChange"
          ref="productCarousel"
      >
        <div
            class="slider--8 d-flex align-center justify-center rfanimation-style--1"
            v-for="(product, i) in products"
            :key="i"
        >
          <v-container>
            <v-row>
              <v-col cols="12">
                <div class="product-show-slide" :data-productid="i">
                  <div class="poss_absolute w-100 h-100 product-show-slide-img-container">
                    <div class="float-left ms-section ms-table poss_relative">
                      <div class="ms-tableCell content">
                        <img :src="product.img" :alt="product.name" class="product-show-img" >
                      </div>
                    </div>
                  </div>
                  <div class="float-right h-100 product-show-slide-text-container">
                    <div class="ms-section ms-table poss_relative">
                    <div class="ms-tableCell content">
                      <h2 class="product-show-slide-name">{{ product.name }}</h2>
                      <p class="product-show-slide-description">
                        {{ product.desc }}
                      </p>
                      <div class="slide-btn mt-10 product-show-slide-button-container">
                        <a class="product-show-slide-button rf-btn" :href="product.url">
                          前往天猫旗舰店
                        </a>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <!-- End Single Slide  -->
      </VueSlickCarousel>
      <div class="nav-product-container">
          <div class="nav-product d-flex">
            <div
                class="d-flex rfanimation-style--1"
                v-for="(product, i) in products"
                :key="i"
            >
              <div :class="selectedProduct === i ? 'nav-product-grid selected' : 'nav-product-grid'"  :data-productid="i" v-on:click="onProductIconClick">
                <img :src="product.icon" :alt="product.name" class="nav-product-icon">
              </div>
            </div>
          </div>
        </div>
    </div>

  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import {isAndroid, isIOS} from "@/main";

export default {
  components: { VueSlickCarousel },
  data() {
    return {
      selectedProduct: 0,
      src: require("../../assets/img/slider/slider-6.jpg"),
      settings: {
        fade: true,
        dots: false,
        arrows: true,
        infinite: true,
        speed: 600,
        slidesToShow: 1,
        slidesToScroll: 0,
        margin: 20,
        shopURL: isAndroid || isIOS ? "https://rikorisu.m.tmall.com/" : "https://rikorisu.tmall.com/",
        autoplay: true,
        autoplaySpeed: 8000
      },
      heroContent: {
        name: "分类展示页",
        title: "还没有做好qwq",
        desc: `ヽ(*。>Д<)o゜`,
      },
      products: [
        {
          "icon": require("../../assets/img/rikorisu/products/1/icon2.png"),
          "name": "套装",
          "img":  require("../../assets/img/rikorisu/products/1/show.png"),
          "desc": "Lycoris Princess（By RIKORISU）系列日制套装",
          "url": "https://rikorisu.world.tmall.com/category-1646955225.htm"
        },
        {
          "icon": require("../../assets/img/rikorisu/products/2/icon.png"),
          "name": "半身裙",
          "img":  require("../../assets/img/rikorisu/products/2/show.png"),
          "desc": "精选日本「黑科技」羊毛面料",
          "url": "https://rikorisu.world.tmall.com/category-1646963510.htm"
        },
        {
          "icon": require("../../assets/img/rikorisu/products/3/icon.png"),
          "name": "毛衣",
          "img":  require("../../assets/img/rikorisu/products/3/show2.png"),
          "desc": "日制/轻盈/柔软/百搭",
          "url": "https://rikorisu.world.tmall.com/category-1646955227.htm"
        },
        {
          "icon": require("../../assets/img/rikorisu/products/4/icon.png"),
          "name": "西装外套",
          "img":  require("../../assets/img/rikorisu/products/4/show.png"),
          "desc": "3D裁剪/修身立体/打理简单",
          "url": "https://rikorisu.world.tmall.com/category-1646955228.htm"
        },
        {
          "icon": require("../../assets/img/rikorisu/products/5/icon.png"),
          "name": "小物",
          "img":  require("../../assets/img/rikorisu/products/5/show.png"),
          "desc": "搭配灵魂，细节加分！",
          "url": "https://detail.tmall.com/item.htm?abbucket=13&id=711921471199"
        }
      ],
    };
  },
  created() {
    this.$nextTick(() => {
      document.querySelector(".nav-product-grid").classList.add("selected");
      console.log(document.querySelector(".nav-product-grid"))
    })
  },
  methods: {
    onProductSlideBeforeChange(oldIndex, newIndex) {
      this.selectedProduct = newIndex;
    },

    onProductIconClick(event) {
      this.$refs.productCarousel.goTo(event.currentTarget.dataset.productid);
      console.log(event.currentTarget.parentElement.parentElement);
      let oldSelected = event.currentTarget.parentElement.parentElement.querySelector(".selected");
      if (oldSelected !== event.currentTarget){
        oldSelected.classList.remove("selected");
        event.currentTarget.classList.add("selected");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.product-slider {
  height: 100vh;
  width: 100vw;
  text-align: center;
  position: absolute;
}
.product-show-slide{
  height: 100vh;
  width: 100%;
  padding: 0 5vw 0 5vw;
}
.container{
  @media (min-width: 1920px){
    max-width: 1800px;
  }
}
.product-show-slide-img-container{
  display: flex;
  justify-content: flex-end;
}
.product-show-slide-text-container{
  display: flex;
  justify-content: flex-start;
  width: 60vw !important;
}
.product-show-img {
  height: 80vh;
  width: auto;
}
.product-show-slide-name{
  text-align: right;
  color: #0c0c0c !important;
  text-shadow: -3px 3px 0 #fff, 3px 3px 0 #fff,3px -3px 0 #fff,-3px -3px 0 #fff;
}
.product-show-slide-description{
  text-align: right;
  color: #0c0c0c !important;
  padding: 0 1% !important;
  text-shadow: -1px 1px 0 #fff, 1px 1px 0 #fff,1px -1px 0 #fff,-1px 1px 0 #fff;
}
.product-show-slide-button-container{
  text-align: right;
}
.product-show-slide-button{
  background: #1E8CBE;
  z-index: 9;
  position: relative;
}
.product-show-slide-button:visited{
  color: #ffffff !important;
}
.nav-product-container {
  display: block;
  width: 100vw;
  position: absolute;
}

.slider-activation-with-slick {
  height: 100vh;
}

.nav-product {
  position: absolute;
  background-image: url("../../assets/img/bg/filter-bg-nav.png");
  background-size: cover;
  bottom: 10vh;
  left: max(-20vh, -20vw);
  justify-content: flex-end;
  width: min(100vh, 100vw);
  padding: 0.8vh;
  border-radius: 2vh;
  background-color: #FFFFFF33;
  box-shadow: #AAAAAA66 ;
}

.nav-product-grid {
  width: min(10vw, 10vh);
  padding: 0.8vh;
  border-radius: 0.8vh;
  border: 2px solid #ffffff00;
  transition: border 300ms;
}

.nav-product-grid.selected {
  border-color: #1E8CBE;
}

.nav-product-icon {
  border: 2px solid #ffffff00;
}
</style>
