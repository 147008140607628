<template>
  <div class="ms-container-wrapper">
    <div class="ms-left">
      <div
          class="ms-section bg_image "
          :style="{ backgroundImage: 'url(' + src + ')' }"
      ></div>
    </div>

    <div class="ms-right">
      <div class="ms-section ms-table">
        <div
            class="slipt-content-inner ms-tableCell story-content footer-widget rn-plr rn-ptb-100"
        >
          <div class="content">
            <h2 class="section-title">联系我们</h2>
            <div class="service-wrapper address">
              <!-- Start Single Service -->
              <div class="service" v-for="(contact, i) in contactInfo" :key="i">
                <h4><img :src="contact.icon" class="mdi mdi-18px d-inline-flex pr-3" style="width: 40px">{{ contact.name }}</h4>
                <p>
                  <a :href="contact.to">{{ contact.desc }}</a>
                </p>
              </div>
              <!-- End Single Service -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {isAndroid, isIOS} from "@/main";

export default {
  data() {
    return {
      src: require("../../assets/img/rikorisu/DSC04412.jpg"),
      contactInfo: [
        {
          name: "天猫",
          desc: "RIKORISU天猫旗舰店",
          icon: require("../../assets/img/rikorisu/contact/tmall.png"),
          to: isAndroid || isIOS ? "https://rikorisu.m.tmall.com/" : "https://rikorisu.tmall.com/",
        },
        {
          name: "微博",
          desc: "@RIKORISU_Uniform",
          icon: require("../../assets/img/rikorisu/contact/weibo.png"),
          to: "https://weibo.com/n/RIKORISU_Uniform",
        },
        {
          name: "小红书",
          desc: "RIKORISU的小红书",
          icon: require("../../assets/img/rikorisu/contact/xhs.png"),
          to: "https://www.xiaohongshu.com/user/profile/623d7368000000001000781e",
        },
        {
          name: "抖音",
          desc: "RIKORISU的主页",
          icon: require("../../assets/img/rikorisu/contact/tiktok.png"),
          to: "https://www.douyin.com/user/MS4wLjABAAAAYuNAvbFFGLablJDzgpPI5HZJYbJAwd_BgeAYpEE14Ve_jsalOFw7ZvsdkpPJwThE",
        },
        {
          name: "微信公众号",
          desc: "RIKORISU品牌公众号",
          icon: require("../../assets/img/rikorisu/contact/wechat.png"),
          to: "#",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  @media (max-width: 1199px){
    margin: 30px;
  }
}
.service-wrapper{
  @media (max-width: 1199px){
    margin: 30px;
    text-align: center;
  }
}
</style>
