<template>
  <!-- Start Single Slider -->
  <div id="rikorisu-page-index">
    <VueSlickCarousel
        v-bind="settings"
        class="slider-activation-with-slick rn-slick-dot dot-light mb--0"
    >
      <div
          class="slider--8 fullscreen d-flex align-center justify-center fullscreen bg_image rfanimation-style--1"
          v-for="(slider, i) in sliderContent"
          :key="i"
          :style="{ backgroundImage: 'url(' + slider.src + ')' }"
      >
        <div class="slide-mask"></div>
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="content text-center">
                <h2 class="heading-title">{{ slider.title }}</h2>
                <p class="description">
                  {{ slider.desc }}
                </p>
                <div class="slide-btn mt-10">
                  <a class="rf-btn" :href="settings.shopURL"
                  >前往天猫旗舰店</a
                  >
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!-- End Single Slide  -->
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import {isAndroid, isIOS} from "@/main";

export default {
  components: { VueSlickCarousel },
  data() {
    return {
      sliderContent: [
        {
          src: require("../../assets/img/rikorisu/slider1.jpg"),
          title: "RIKORISU",
          desc: `让青春更耀眼！`,
        },
        {
          src: require("../../assets/img/rikorisu/slider2.jpg"),
          title: "RIKORISU",
          desc: `让青春更耀眼！`,
        },
        {
          src: require("../../assets/img/rikorisu/slider3.jpg"),
          title: "RIKORISU",
          desc: `让青春更耀眼！`,
        },
        {
          src: require("../../assets/img/rikorisu/slider4.jpg"),
          title: "RIKORISU",
          desc: `让青春更耀眼！`,
        },
        {
          src: require("../../assets/img/rikorisu/slider5.jpg"),
          title: "RIKORISU",
          desc: `让青春更耀眼！`,
        },
      ],
      settings: {
        fade: true,
        dots: true,
        arrows: true,
        infinite: true,
        speed: 600,
        slidesToShow: 1,
        slidesToScroll: 0,
        margin: 20,
        autoplay: true,
        autoplaySpeed: 8000,
        shopURL: isAndroid || isIOS ? "https://rikorisu.m.tmall.com/" : "https://rikorisu.tmall.com/"
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.fullscreen{
  min-height: 100vh !important;
}
</style>

<style lang="scss">
.slick-slide {
  img {
    display: block;
    width: 100%;
  }
}
.slide-mask {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IArs4c6QAAAE1JREFUOE9j5BaRuvnr+2cGNk5eBhighM8IMhBuEhUYKAZS4jKQW0D6aeNCargMFge0cSE1Ync0DCGhSI3Ypk0sU8Nlo+lwtDykoOQGAAxCqvFBleXSAAAAAElFTkSuQmCC) repeat 0 0/.2rem .2rem;
  filter: opacity(0.6);
}
.slick-dots li {
   margin: 0 20px !important;

  @media (max-width: 767px){
    margin: 0 15px !important;
  }

  @media (max-width: 599px){
    margin: 0 10px !important;
  }

  @media (max-width: 399px){
    margin: 0 7px !important;
  }
}
.slick-dots li.slick-active button:after {
  width: 60px;
  height: 60px;
  background-image: url("../../assets/img/logo/rikorisu-square-white.png") !important;
  background-size: cover;
  left: -28px;
  top: -27px;
  border: none !important;
}
</style>
