<template>
  <div class="ms-container-wrapper">

    <div class="ms-left">
      <div class="ms-section ms-table">
        <div
            class="slipt-content-inner ms-tableCell award-content rn-plr rn-ptb-100"
        >
          <div class="content">
            <h2 class="section-title">关于我们</h2>

            <ul class="rn-award-list">
              <li v-for="(award, i) in awardContent" :key="i" @click="aboudDetailCollapseClicked">
                <p class="font-weight-bold">{{ award.name }}</p>
                <div class="about-detail mt-2 pa-2 collapsed" v-html="award.full">
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="ms-right">
      <div
          class="ms-section bg_image float-right"
          :style="{ backgroundImage: 'url(' + src + ')', maxWidth: '700px', width:'700px' }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      src: require("../../assets/img/rikorisu/DSC04038.jpg"),
      awardContent: [
        {
          url: "https://www.awwwards.com/",
          name: "RIKORISU品牌介绍",
          position: "Our Brands",
          year: "but Anytime!",
          full: "<div class='about-detail-title'>RIKORISU</div><p>品牌成立于2020年。</p><p>“我想让国内消费者穿到属于自己的日制校供品质制服。</p><p style='text-align: end; width: 100%'>——RIKORISU品牌主理人徐梦云女士如是说。</p>" +
              "<p>此前，国内JK制服的爱好者都存在日制制服只能代拍、代购，购买渠道单一且有风险、到货时间长等问题，为了解决这些问题，徐梦云女士走访多家日本校供服装及知名面料工厂寻求合作，采用日本最先进的布料加工工艺，每一道工序都有着最严格的质量把控，确保不使用任何对人体有害的化学物质，即使是皮肤敏感人群也可以安心穿着。故此，徐梦云女士以高端日制校供制服为标杆，创立了品牌RIKORISU。</p>"
        },
        {
          url: "https://www.csswinner.com/",
          name: "Lycoris Princess品牌介绍",
          position: "上海",
          year: "2023年1月",
          full: "<div class='about-detail-title'>Lycoris Princess</div>" +
              "<p>Lycoris Princess系RIKORISU品牌旗下的高端制服品牌，从打板到制作均由日本专业校供工厂专人负责，选用日本高端校供同品质面料，将更好的制服带给大家。品牌更像是一个先行者，秉承着制服文化的时尚理念，诠释新世代制服的态度，旨为满足国内日制校供制服爱好者们的需求而决定开发的时尚制服品牌线。</p>"
        },
        {
          url: "https://www.designnominees.com/",
          name: "HELLO STUDENTxRIKORISU品牌联名介绍",
          position: "某个地方",
          year: "2021年8月",
          full: "<div class='about-detail-title mb-0'>日本被服株式会社旗下品牌</div>" +
              "<div class='about-detail-title'>HELLO STUDENT</div>" +
              "<p>日本被服株式会社成立于1930年，作为日本的先驱制服制造商，致力于“创造有辨识力的产品和服务”这一准则，并将其作为企业使命在真诚且热情地去执行下去。「HELLO STUDENT」品牌系日本被服株式会社旗下制服品牌，专注于制作出拥有着独自的原创设计，也有着很高的普遍性，是有着舒服的穿着感和舒适性的学生制服。</p>" +
              "<div class='about-detail-title mt-3'>HELLO STUDENT for RIKORISU</div>" +
              "<p>「HELLO STUDENT for RIKORISU」系列制服以百搭和经典为主的设计风格，延续传统制服的风格，融入新时尚的元素，多种面料和颜色的选择，赋予其独特且舒适的质感，带来穿着新体验！</p>"
        },
        {
          url: "https://www.cssdesignawards.com/",
          name: "HANECTONExRIKORISU品牌联名介绍",
          position: "深圳",
          year: "2021年2月",
          full: "<div class='about-detail-title mb-0'>HANECTONE品牌</div>" +
              "<p>1930年，ハネクトーン早川株式会社作为学校领结专业制造商成立。</p>" +
              "<p>近百年来，作为制服制造商虽然扩大了经营领域，但是对「制造出质量可靠的领结」的承诺从未改变。</p>" +
              "<p>以日本专业工厂为核心，有着精湛技艺的工匠在裁剪、缝制、规格等环节都追求极致的精准，实现了每一件产品都有着很高的完成度。</p>" +
              "<p>日本的校园时尚现在正吸引着全世界的目光。HANECTONE始终倾听时代的声音，同时也为自身的创新理念和新鲜设计而自豪，这些创意和设计源于日常的巧思，保持传统的同时而不惧怕自我创新。</p>" +
              "<div class='about-detail-title mt-3'>HANECTONExRIKORISU</div>" +
              "<p>RIKORISU希望通过鲜明独特的立意来打造联名领结，从而在新意和创意之间建立起真正的连接。为此，RIKORISU与日本超高人气领结品牌HANECTONE联手打造出HANECTONExRIKORISU的联名系列领结。将独特的领结款型和鲜艳的色彩合二为一，让青春更耀眼！</p>"
        },
      ],
    };
  },
  methods: {
    aboudDetailCollapseClicked(event){
      let opened = event.currentTarget.parentElement.querySelector(".uncollapsed")
      let current = event.currentTarget.querySelector(".about-detail");
      if (opened){
        opened.classList.remove('uncollapsed');
        opened.classList.add("collapsed");
      }
      if (current !== opened){
        current.classList.remove("collapsed");
        current.classList.add("uncollapsed");
      }
    }
  }
};
</script>

<style lang="scss">
ul.rn-award-list li{
  border-bottom: solid 1px #2a2a2a;
  margin-top: 20px !important;
}
ul.rn-award-list li:last-child{
  display: list-item !important;
}
div.about-detail {
  transition: height 1s ease-in-out;
  animation: 1000ms ease-in-out 0s normal none 1 running;
  overflow: hidden;
  height: 48vh;

  @media (max-width: 776px) {
    overflow-y: scroll;
  }
}
div.about-detail p {
  text-indent: 40px;
  margin-bottom: 5px !important;

  @media (max-width: 776px) {
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
  }
}
div.about-detail .about-detail-title{
  text-align: center;
  font-weight: bold;
  margin-bottom: 12px;
  font-size: 20px;
}
div.about-detail.collapsed{
  height: 0;
  padding: 0 !important;
}
</style>
