<template>
  <div>
    <HeaderMultiPageRoute>
      <img slot="logo" src="../../assets/img/logo/rikorisu-long-nologo.png" />
    </HeaderMultiPageRoute>
    <!-- End Header -->

    <hooper :settings="settings" class="multiscroll-slider-wrapper">
      <slide>
        <SilderR />
      </slide>
      <slide>
        <Goods />
      </slide>
      <slide>
        <News />
      </slide>
      <slide>
        <Contact />
      </slide>
      <slide>
        <Legal />
      </slide>
      <hooper-navigation slot="hooper-addons"></hooper-navigation>
      <hooper-pagination slot="hooper-addons"></hooper-pagination>
    </hooper>
    <!-- End hooper slider content -->
  </div>
</template>

<script>
import HeaderMultiPageRoute from "../../components/header/HeaderMultiPageRoute.vue";
import News from "@/components/rikorisu/News.vue";
import Contact from "@/components/rikorisu/Contact.vue";
import { Hooper, Slide, Pagination as HooperPagination, Navigation as HooperNavigation } from "hooper";
import SilderR from "@/components/rikorisu/SilderR.vue";
import Goods from "@/components/rikorisu/Goods.vue";
import Legal from "@/components/rikorisu/Legal.vue";

export default {
  components: {
    News,
    Goods,
    SilderR,
    HeaderMultiPageRoute,
    Hooper,
    Slide,
    HooperPagination,
    HooperNavigation,
    Contact,
    Legal
  },
  data() {
    return {
      settings: {
        vertical: true,
        mouseDrag: false,
        infiniteScroll: true,
        transition: 800,
      },
    };
  },
};
</script>

<style lang="scss">
.ms-right,
.ms-section.ms-table,
.ms-tableCell {
  height: 100%;
}
.ms-section.ms-table {
  display: table;
  width: 100%;
}
.ms-tableCell {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
}
.ms-container-wrapper {
  display: flex;
  height: 100vh;
  background: rgba(255, 255, 255, 0.4);

  .ms-left {
    flex: 0 0 50%;
    max-width: 50%;
    @media only screen and (max-width: 1199px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .ms-right {
    flex: 0 0 50%;
    max-width: 50%;
    @media only screen and (max-width: 1199px) {
      flex: 0 0 100%;
      max-width: 100%;
      position: absolute;
      width: 100%;
      height: 100vh;
    }
  }
  .ms-section {
    height: 100%;
    @media only screen and (max-width: 767px) {
      padding: 80px 0;
    }
  }
  .bg_image {
    @media only screen and (max-width: 1199px) {
      background-color: #ffffff99;
      background-blend-mode: lighten;
    }
  }
}
.multiscroll-slider-wrapper {
  background: url("../../assets/img/bg/white-woven-fabric.jpg") repeat center center;
  background-size: cover;
}
.multiscroll-slider-wrapper.hooper {
  @media only screen and (max-width: 767px) {
    .hooper-indicators {
      display: none;
    }
  }
  .hooper-pagination.is-vertical {
    @media only screen and (max-width: 1199px) {
      right: 15px;
      padding: 0;
    }
  }
  .hooper-pagination {
    top: 75%
  }
}
.multiscroll-slider-wrapper
.hooper-pagination.is-vertical
.hooper-indicator::after,
.multiscroll-slider-wrapper .hooper-pagination.is-vertical .hooper-indicator {
  transition: all 0.5s ease-in-out;
}
</style>
